import api from "../../config/api";
import handleError from "../../utils/handleError";


export default {
  async setBanks({ commit }) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("/domain/list/bank");
      commit("SET_BANKS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setSegments({ commit }, profile) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`/domain/list/segment/${profile}`);
      commit("SET_SEGMENTS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async domainProperty({ commit }) {
    commit("CLEAR_ERROR");

    try {
      const { data } = await api.get("/domain/list/pos");
      commit("SET_DOMAIN_PROPERTYS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setConsultRegistration({ commit }) {
    commit("CLEAR_ERROR");

    try {
      const { data } = await api.get("/customer/");
      commit("SET_CONSULT_REGISTRATION", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async changePassword({ commit }, payload = {}) {
    commit("CLEAN_PASSWORD_MESSAGE");
    try {
      await api.post("/user/password/", payload);
      commit("SET_SUCCESS_PASSWORD", "Senha atualizada com sucesso.");
    } catch (error) {
      commit("SET_ERROR_PASSWORD", handleError(error));
    }
  },
  async getDocument({ commit }, payload = {}) {
    commit("CLEAR_ERROR");
    try {
      const reponseDocument = await api.get(`customer/document/${payload}`);
      if (reponseDocument.data.exists) {
        commit("SET_ERROR", "CNPJ já cadastrado");
        return;
      }
      const { data } = await api.get(`partner/audicon/${payload}`);
      if (!data.status) {
        commit("SET_ERROR", "CNPJ inativo na Receita Federal");
        return;
      }
      commit("SET_SUCCESS_DOCUMENT", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async getSuppliers({ commit }) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("/customer/profile/supplier");
      commit("SET_SUPPLIERS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async createCustomer({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("customer/", payload);
      commit("SET_CUSTOMER", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async updateStatus({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.put(`customer/${payload.id}/status/${payload.status}`, "");
      commit("SET_STATUS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async createUser({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("user/", payload);
      commit("SET_USER", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },

  async getPostalCode({ commit }, postalcode) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`customer/postalcode/${postalcode}`);
      commit("SET_ADDRESS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },

  async createContract({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const response = await api.post('customer/contract', payload);
      const linkContract = response.headers['link'];
      commit('SET_LINK_CONTRACT', linkContract)
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async downloadContract({ commit }, _payload) {
    commit('CLEAR_ERROR');
    try {
      const response = await api.post('customer/contract', _payload, { responseType: 'blob'},);
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob)
      return url;
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async downloadContractByUuid({ commit }, _payload) {
    commit('CLEAR_ERROR');
    try {
      const response = await api.get(`customer/file/${_payload}`, { responseType: 'blob'},);
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob)
      return url;
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },
  async userValidate({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("user/validate", payload);
      commit("SET_USER_VALIDATE", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setCreatePassword({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.put("user/create-password", payload);
      commit("SET_SUCCESS_PASSWORD", data.message);
      commit("SET_NEW_USER", payload);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  
};
