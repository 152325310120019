const authRoutes = [
  {
    path: '/',
    name: 'auth',
    component: () => import('@/views/Template/Container.vue'),
    redirect: { name: 'login' },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/Login.vue'),
        meta: {
          requiresAuth: false,
          userLogged: false,
          showMenu: false,
          title: 'Login',
        },
      },
      {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/login/registration/Registration.vue'),
        meta: {
          requiresAuth: false,
          userLogged: false,
          showMenu: false,
          title: 'Cadastro',
        },
      },
      {
        path: 'forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/views/commons/forgotPassword/forgotPassword.vue'),
        meta: {
          requiresAuth: false,
          showMenu: false,
          showToolbar: false,
          title: 'Recuperar senha',
          breadCrumb: 'Recuperar senha',
        },
      },
      {
        path: 'resetPassword',
        name: 'Resetar senha',
        component: () => import('@/views/commons/resetPassword/resetPassword.vue'),
        meta: {
          requiresAuth: false,
          showMenu: false,
          showToolbar: false,
          title: 'Resetar senha',
          breadCrumb: 'Resetar senha',
        },
      },
      {
        path: 'digitalAccount/resetPassword',
        name: 'Resetar senha digital',
        component: () => import('@/views/commons/resetPassword/resetDigitalPassword.vue'),
        meta: {
          requiresAuth: false,
          showMenu: false,
          showToolbar: false,
          title: 'Resetar senha digital',
          breadCrumb: 'Resetar senha digital',
        },
      },
      {
        path: 'firstAccess',
        name: 'firstAccess',
        component: () => import('@/views/login/firstAccess/Index.vue'),
        meta: {
          requiresAuth: false,
          showMenu: false,
          showToolbar: false,
          title: 'Primeiro acesso',
          breadCrumb: 'Primeiro acesso',
        },
      },
    ],
  },
];


export default authRoutes;
