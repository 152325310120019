export default {
  SET_CONSULT_STATEMENT(state, _payload) {
    state.consultStatement = _payload;
  },
  SET_FUTURE_RELEASE(state, _payload) {
    state.futureRelease = _payload;
  },
  SET_ERROR(state, _payload = true) {
    state.error = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_ACCOUNT(state, _payload) {
    state.account = _payload;
  },
  SET_FILE(state, _payload) {
    state.fileStatement = _payload;
  },
  SET_FILENAME(state, _payload) {
    state.fileName = _payload;
  },
  SET_BALANCE(state, _payload) {
    state.balance = _payload;
  },
  SET_ERROR_PASSWORD(state, payload) {
    state.errorPassword = payload;
  },
  SET_SUCCESS_PASSWORD(state, payload) {
    state.successPassword = payload;
  },
  SET_PASSWORD_CREATED(state, payload) {
    state.passwordCreated = payload;
  },
  SET_PASSWORD_FAIL(state, payload) {
    state.passwordFail = payload;
  },
  SET_SHOW_PASSWORD_MODAL(state, payload) {
    state.showPasswordModal = payload;
  },

  CLEAN_PASSWORD_MESSAGE(state) {
    state.successPassword = '';
    state.errorPassword = '';
  },
  SUCCESS_DISABLE_DIGITAL_ACCOUNT(state) {
    state.successDisableDigitalAccount = true;
  },
  CLEAR_SUCCESS_DISABLE_ACCOUNT(state) {
    state.successDisableDigitalAccount = false;
  },
  SET_CONTACT(state, payload) {
    state.contact = payload;
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },
  SUCCESS_CREATE_CONTACT(state) {
    state.successCreateContact = true;
  },
  SUCCESS_UPDATE_CONTACT(state) {
    state.successUpdateContact = true;
  },
  SUCCESS_DELETE_CONTACT(state) {
    state.successDeleteContact = true;
  },
  CLEAR_SUCCESS_DELETE_CONTACT(state) {
    state.successDeleteContact = false;
  },
  SET_TOKEN(state, payload) {
    state.transferToken = payload;
  },
  SET_TRANSFER(state, payload) {
    state.transfer = payload;
  },
  SET_SUCCESS_TRANSFER(state) {
    state.successTransfer = true;
  },
  CONTACT_CREATED(state, payload) {
    state.contactCreated = payload;
  },
  CLEAR_SUCCESS_TRANSFER(state) {
    state.successTransfer = false;
  },
  SET_RECEIPT_TRANSFER(state, payload) {
    state.receiptTransfer = payload;
  },
  SET_BILL(state, payload) {
    state.bill = payload;
  },
  SUCCESS_SEND_EMAIL(state) {
    state.sendEmail = true;
  },
  CLEAR_SEND_EMAIL(state) {
    state.sendEmail = false;
  },
  SET_ID_TRANSFER(state, payload) {
    state.idTransfer = payload;
  },
  SET_SEND_EMAIL_PASSWORD(state, payload) {
    state.emailPassword = payload;
  },
  SET_SEND_EMAIL_PASSWORD_ERROR(state, payload) {
    state.emailPasswordError = payload;
  },
  SET_RESET_PASSWORD(state, payload) {
    state.resetPassword = payload;
  },
  SET_VALID_TOKEN(state, payload) {
    state.validToken = payload;
  },
  SET_BARCODE_DATA(state, payload) {
    state.barCodeData = payload;
    state.barCodeData.paymentDetails.totalAmount = Number(
      state.barCodeData.paymentDetails.totalAmount,
    ).toFixed(2);
    state.barCodeData.originalAmount = state.barCodeData.paymentDetails.totalAmount;
  },
  SET_SUCCESS_PAYMENT(state, payload) {
    state.successPayment = payload;
  },
  SET_ACCOUNT_TRANSFER(state, _payload) {
    state.account = _payload;
  },
};
