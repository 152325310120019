<template>
  <v-container fluid>
    <v-row v-if="$route.meta.requiresAuth" :class="{'my-0' : $vuetify.breakpoint.mdAndDown}">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" :cols="getCollapsedMenu ? '1' : '2' " class="pa-0">
        <Sidebar v-if="$route.meta.showMenu" />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" class="contentToolbar py-0 px-6 ">
        <v-row>
          <v-snackbar v-model="snackbar" :timeout="timeout" top color="red">
            {{ globalError }}
            <v-btn @click="snackbar = false" text>Fechar</v-btn>
          </v-snackbar>
          <v-row>
            <Toolbar v-if="$route.meta.showToolbar" />
          </v-row>
          <v-col md="12" class="breadCrumbs pb-0">
            <v-breadcrumbs class="pb-0">
              <span @click="pushRouter()" class="title-menu">{{routerBreadCrumb}}</span>
            </v-breadcrumbs>
          </v-col>
          <v-col cols="12">
            <slot></slot>
          </v-col>
        </v-row>
      </v-col>

      <!-- Mobile -->
      <Sidebar v-if="$route.meta.showMenu && $vuetify.breakpoint.mdAndDown" />
      <v-col v-if="$vuetify.breakpoint.mdAndDown" class="contentToolbar pa-0 px-1" md="10">
        <v-snackbar v-model="snackbar" :timeout="timeout" top color="red">
          {{ globalError }}
          <v-btn @click="snackbar = false" text>Fechar</v-btn>
        </v-snackbar>
        <Toolbar v-if="$route.meta.showToolbar" />
        <v-row class="my-0">
          <v-col md="12" class="breadCrumbs pb-0">
            <v-breadcrumbs class="pb-0">
              <span @click="pushRouter()" class="title-menu">{{routerBreadCrumb}}</span>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <slot></slot>
      </v-col>
      
    </v-row>
    <v-row v-else>
      <slot></slot>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Sidebar from '../../components/Sidebar/index.vue';
import Toolbar from '../../components/Toolbar/Toolbar.vue';

export default {
  components: {
    Toolbar,
    Sidebar,
  },
  methods: {
    ...mapActions({
      setUserInfo: 'User/setUserInfo',
    }),
    checkTokenExpDate() {
      setTimeout(() => {
        const token = localStorage.TOKEN && this.$jwt.decode(localStorage.TOKEN);
        if (token) {
          if (this.$moment().unix() >= token.exp) {
            this.setUserInfo();
          }
          this.checkTokenExpDate();
        } else {
          localStorage.removeItem('TOKEN');
          this.$router.push({ path: '/login' });
        }
      }, 100 * 60 * 60); /* 1 hour */
    },
    async consultKey() {
      if(this.getModalStatus){
        if(this.getModalStatus.showAliasModal === true){
          this.setKeyExists(false)
        } else {
          this.setKeyExists(true)
        }
      }
    },
    ...mapMutations({
      clearError: 'Error/CLEAR_ERROR',
      setKeyExists: 'Pix/SET_KEY_EXISTS'
    }),
  },
  data: () => ({
    snackbar: false,
    timeout: 5000,
  }),
  computed: {
    ...mapGetters({
      userInfo: 'User/getInfo',
      logged: 'Auth/getLogged',
      globalError: 'Error/getError',
      getDigitalAccount: 'DigitalAccount/getAccount',
      getKeyExists: 'Pix/getKeyExists',
      getDataKeys:'Pix/getAllDataKeys',
      getModalStatus: 'Pix/getModalStatus',
      getCollapsedMenu: 'User/getCollapsedMenu'
    }),
    routerBreadCrumb() {
      return this.$route.meta.breadCrumb;
    },
  },
  watch: {
    logged(value) {
      if (!value) {
        this.$router.push({ path: '/login' });
      }
    },
    snackbar(value) {
      if (!value) this.clearError();
    },
    globalError() {
      if (this.globalError) this.snackbar = true;
    },
  },
  mounted() {
    this.checkTokenExpDate();
  },
  async created() {
    this.setUserInfo(localStorage.TOKEN);
    await this.consultKey();
  },
};
</script>
<style lang="scss" scoped>
.contentToolbar {
  display: flex;
}

.container--fluid {
  max-width: 95%;
  min-width: 900px;
  padding-left: 30px;
}

.breadCrumbs {
  margin: 5% 0% 0% 0%;
  padding-left: 0%;
  font-size: 24px;
}

.title-menu {
  white-space: nowrap;
}
@media only screen and (max-device-width: 767px) {
  .breadCrumbs {
    font-size: 18px;
    margin: 0% 0% 0% 0%;
  }

  .title-menu {
    margin-left: 5%;
  }
  .contentToolbar {
    display: block;
    width: auto;
    margin-top: 10%;
    margin-right: 0.5%;
  }
  .container--fluid {
    max-width: 95%;
    min-width: 0;
    padding: 0px;
  }
}
</style>
