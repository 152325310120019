<template>
  <v-app-bar class="top-bar">
    <div @click="logoHome" class="logo">
      <img src="../../../public/favicon/favicon-16x16.png" alt="finago">
    </div>
    <v-app-bar-nav-icon @click="showMenu" class="icon-nav"></v-app-bar-nav-icon>

    <v-spacer></v-spacer>

    <v-btn icon class="icon">
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <v-btn icon class="icon">
      <v-icon>mdi-email</v-icon>
    </v-btn>

  <v-divider
    class="mx-4"
    inset
    vertical
  ></v-divider>

    <v-menu
      left
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon class="icon-account">mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(options, optionsKey) in userOptions"
          :key="optionsKey"
          @click="changeRoute(options.to)"
        >
          <v-icon class="icon-options">{{ options.userIcons }}</v-icon>
          <v-list-item-title class="userOptions">{{ options.userOption }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations({
      setLogin: 'Auth/SET_LOGIN',
      setUserInfo: 'User/SET_USER_INFO',
    }),
    pushRouter() {
      this.$router.push({ name: `${this.$router.history.current.name}` });
    },
    changeRoute(route) {
      if (this.$route.path === route) return;
      if (route === '/login') {
        this.logout();
      }
      this.$router.push(route);
    },
    showMenu() {
      this.$bus.$emit('toggleMenu', true);
    },
    logout() {
      this.setLogin({
        login: '',
        roles: [],
      });
      this.setUserInfo({});
      localStorage.clear();
    },
    logoHome() {
      const home = 'home';
      return this.$router.push({ path: `/customer/${home}` });
    },
  },
  computed: {
    ...mapGetters('Auth', ['getRole']),
    userOptions() {
      const options = [
        { userOption: 'Meu cadastro', userIcons: 'mdi-account-card-details', to: `/customer/myRegistration` },
        { userOption: 'Senhas', userIcons: 'mdi-key-variant', to: `/customer/changePassword` },
        { userOption: 'Contratos', userIcons: 'mdi-file-document', to: `/customer/myContracts` },
        { userOption: 'Sair', userIcons: 'mdi-logout', to: '/login' },
      ];
      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  width: 50%;
  top: 40px !important;
  white-space: nowrap !important;
  right: 1% !important;
}

  .icon {
    color: rgb(117, 117, 117) !important;
  }

  .top-bar {
    color: white;
    width: 1000px;
    position:fixed;
    margin-top: -11% !important;
    z-index: 1;
  }

  .icon-account {
    color: #282E62 !important;
  }

  .icon-nav {
    color: #282E62 !important;
  }

  .userOptions {
    color: #8C8C8C;
    font-weight: bold;
    font-size: 15px;
  }

  .icon-options {
    padding-right: 10%;
  }
  .logo {
    margin-top: 2%;
  }

</style>
