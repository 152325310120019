export default {
  getBanks(state) {
    return state.banks;
  },
  getSegments(state) {
    return state.segments;
  },
  getConsultRegistration(state) {
    return state.consultRegistration;
  },
  getError(state) {
    return state.error;
  },
  getPasswordSuccess(state) {
    return state.successPassword;
  },
  getPasswordError(state) {
    return state.errorPassword;
  },
  getDataDocument(state) {
    return state.dataDocument;
  },
  getSuppliers(state) {
    return state.suppliers;
  },
  getCustomer(state) {
    return state.customer;
  },
  getUser(state) {
    return state.user;
  },
  getDomainPropertys(state) {
    return state.domainPropertys;
  },
  getStatusCustomer(state) {
    return state.statusCustomer;
  },
  getAddress(state) {
    return state.address;
  },
  getLinkContract(state) {
    return state.linkContract;
  },
  getUserValidate(state){
    return state.userValidate;
  },
  getNewUser(state){
    return state.newUser
  }
};
