export default {
  SET_LOGIN(state, _payload) {
    state.login = _payload;
  },
  SET_MESSAGE(state, _payload = '') {
    state.messagePassword = _payload;
  },
  SET_ERROR_PASSWORD(state, _payload = '') {
    state.errorPassword = _payload;
  },
  SET_LOGIN_CREATED(state, _payload = '') {
    state.messages.created = _payload;
  },
  SET_ERROR(state, payload = true) {
    state.error = payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_VALID_TOKEN(state, payload) {
    state.validToken = payload;
  },
};
