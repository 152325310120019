export default {
  login: {
    login: '',
    roles: [],
  },
  messages: {
    created: false,
  },
  checkAuth() {
    return localStorage.getItem('TOKEN');
  },
  logout() {
    localStorage.removeItem('TOKEN');
  },
  messagePassword: '',
  errorPassword: false,
  error: false,
  validToken: false,
  logged: false,
};
