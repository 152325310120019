export default {
  consultStatement: [],
  futureRelease: [],
  error: null,
  account: null,
  fileName: null,
  fileStatement: null,
  showPasswordModal: false,
  balance: 0,
  errorPassword: "",
  successPassword: "",
  passwordCreated: "",
  passwordFail: "",
  contact: null,
  contacts: null,
  successDisableDigitalAccount: false,
  successCreateContact: false,
  successUpdateContact: false,
  successDeleteContact: false,
  transferToken: null,
  transfer: null,
  bill: null,
  successTransfer: false,
  receiptTransfer: null,
  sendEmail: null,
  idTransfer: null,
  emailPassword: false,
  emailPasswordError: false,
  resetPassword: false,
  validToken: false,
  successPayment: false,
  barCodeData: null,
  accountTransfer: null,
  contactCreated: null
};
