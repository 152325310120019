export default {
  SET_RELATED_COSTUMERS(state, _payload) {
    state.customers = _payload;
  },
  SET_TOTAL_PAGES(state, _payload) {
    state.totalPages = _payload;
  },
  SET_ADD_PROVIDER(state, _payload) {
    state.AddProvider = _payload;
  },
  SET_IDS_SUPPLIERS(state, _payload) {
    state.IdsSuppliers = _payload;
  },
  SET_DELETE_SUPPLIERS(state, _payload) {
    state.deleteSuppliers = _payload;
  },
  SET_SUPPLIER_NAME(state, _payload = '') {
    state.supplierName = _payload;
  },
  SET_ERROR(state, _payload) {
    state.error = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = '';
  },
  CLEAR_ADD_PROVIDER(state) {
    state.AddProvider = false;
  },
  CLEAR_IDS_SUPPLIERS(state) {
    state.IdsSuppliers = false;
  },
  CLEAR_DELETE_SUPPLIERS(state) {
    state.deleteSuppliers = false;
  },
};
