<template>
  <v-app id="app">
    <TemplateProvider>
      <router-view/>
    </TemplateProvider>
  </v-app>
</template>

<script>

import TemplateProvider from './views/Template/Backoffice.vue';

export default {
  name: 'App',
  components: { TemplateProvider },
};
</script>
