export default {
  getInfo(state) {
    return state.info;
  },
  getError(state) {
    return state.error;
  },
  getRolesAvailable(state) {
    return state.rolesAvailable
  },
  getCollapsedMenu(state) {
    return state.collapsedMenu;
  }
};
