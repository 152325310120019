export default {
  dataRegistration: {
    representative: {
      address: {}
    }
  },
  banks: [],
  segments: [],
  consultRegistration: {},
  error: null,
  errorPassword: "",
  successPassword: "",
  dataDocument: [],
  suppliers: [],
  customer: [],
  user: [],
  domainPropertys: [],
  statusCustomer: null,
  address: null,
  linkContract: null,
  userValidate: false,
  newUser: {}
};
