export default {
  getConsultStatement(state) {
    return state.consultStatement;
  },
  getFutureRelease(state) {
    return state.futureRelease;
  },
  getAccount(state) {
    return state.account;
  },
  getShowAccount(state) {
    return state.account && state.account.accountStatus !== 'CLOSED';
  },
  getSuccessDisableDigitalAccount(state) {
    return state.successDisableDigitalAccount;
  },
  getFileName(state) {
    return state.fileName;
  },
  getFile(state) {
    return state.fileStatement;
  },
  getError(state) {
    return state.error;
  },
  getBalance(state) {
    return state.balance;
  },
  getPasswordSuccess(state) {
    return state.successPassword;
  },
  getPasswordError(state) {
    return state.errorPassword;
  },
  getPasswordCreated(state) {
    return state.passwordCreated;
  },
  getPasswordFail(state) {
    return state.passwordFail;
  },
  getShowPasswordModal(state) {
    return state.showPasswordModal;
  },
  getContact(state) {
    return state.contact;
  },
  getContacts(state) {
    return state.contacts;
  },
  getSuccessCreateContact(state) {
    return state.successCreateContact;
  },
  getSuccessUpdateContact(state) {
    return state.successUpdateContact;
  },
  getSuccessDeleteContact(state) {
    return state.successDeleteContact;
  },
  getTransfer(state) {
    return state.transfer;
  },
  getSuccessTransfer(state) {
    return state.successTransfer;
  },
  getReceiptTransfer(state) {
    return state.receiptTransfer;
  },
  getBill(state) {
    return state.bill;
  },
  getSendEmail(state) {
    return state.sendEmail;
  },
  getIdTransfer(state) {
    return state.idTransfer;
  },
  getEmailPassword(state) {
    return state.emailPassword;
  },
  getEmailPasswordError(state) {
    return state.emailPasswordError;
  },
  getResetPassword(state) {
    return state.resetPassword;
  },
  getValidToken(state) {
    return state.validToken;
  },
  getBarCodeData(state) {
    return state.barCodeData;
  },
  getSuccessPayment(state) {
    return state.successPayment;
  },
  getAccountTransfer(state) {
    return state.accountTransfer;
  },
  getContactCreated(state) {
    return state.contactCreated;
  },
};
