import api from "../../config/api";
import handleError from "../../utils/handleError";

export default {
  async consultStatement({ commit }, _payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("/digital-checking-account/account/statement", _payload);
      commit("SET_CONSULT_STATEMENT", data);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async futureRelease({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("/digital-checking-account/account/future-release");
      commit("SET_FUTURE_RELEASE", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setAccount({ commit }) {
    try {
      const { data } = await api.get("/digital-checking-account/account");
      commit("SET_ACCOUNT", data);
    } catch (error) {
      commit("SET_ACCOUNT", null);
    }
  },
  async downloadStatement({ commit }, _payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const response = await api.post(
        "/digital-checking-account/account/statement/download",
        _payload
      );
      const fileName = response.headers["content-disposition"].split('"')[1];

      commit("SET_FILE", response.data);
      commit("SET_FILENAME", fileName);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setBalance({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("/digital-checking-account/account/balance");
      commit("SET_BALANCE", data.balance);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async changePassword({ commit }, payload = {}) {
    commit("CLEAN_PASSWORD_MESSAGE");
    try {
      await api.put("/digital-checking-account/account/password", payload);
      commit("SET_SUCCESS_PASSWORD", "Senha atualizada com sucesso.");
    } catch (error) {
      commit("SET_ERROR_PASSWORD", handleError(error));
    }
  },
  async createPassword({ commit, dispatch }, payload = {}) {
    commit("CLEAN_PASSWORD_MESSAGE");
    try {
      await api.post("digital-checking-account/account/password", payload);
      commit("SET_PASSWORD_CREATED", "Senha criada com sucesso.");
      dispatch("DigitalAccount/setAccount", false, { root: true });
    } catch (error) {
      commit("SET_PASSWORD_FAIL", handleError(error));
    }
  },
  async getAccountByDocument({ commit }, payload = {}) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`/digital-checking-account/account/${payload}`);
      commit("SET_ACCOUNT_TRANSFER", data);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
      commit("SET_ERROR", handleError(error));
    }
  },
  async disableDigitalAccount({ commit }, payload = {}) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      await api.delete(`/digital-checking-account/account/${payload}`);
      commit("SUCCESS_DISABLE_DIGITAL_ACCOUNT");
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async createContact({ commit }, payload = {}) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/contact", payload);
      commit("SUCCESS_CREATE_CONTACT");
      commit("CONTACT_CREATED", data)
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async updateContact({ commit }, payload = {}) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { _id } = payload;
      await api.put(`digital-checking-account/contact/${_id}`, payload);
      commit("SUCCESS_UPDATE_CONTACT");
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async getAllContacts({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("digital-checking-account/contact/all");
      commit("SET_CONTACTS", data.items);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async deleteContact({ commit }, id) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      await api.delete(`/digital-checking-account/contact/${id}`);
      commit("SUCCESS_DELETE_CONTACT");
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async getAllContactsBySearchText({ commit }, text) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`/digital-checking-account/contact/search/${text}`);
      commit("SET_CONTACTS", data.items);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async generateTokenTransfer({ commit }, password) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("/digital-checking-account/authorization", { password });
      commit("SET_TOKEN", data.token);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async validateTransfer({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("/digital-checking-account/transfer/validate", payload);
      commit("SET_TRANSFER", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async approveTransfer({ commit, state }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    const { favorited, ...body } = payload;
    const headers = {
      "digital-account-token": state.transferToken
    };
    if (favorited) {
      headers["create-contact"] = true;
    }
    try {
      const { data } = await api.post("/digital-checking-account/transfer/approve", body, {
        headers
      });
      commit("SET_SUCCESS_TRANSFER");
      commit("SET_ID_TRANSFER", data.data.transactionId);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async receiptTransfer({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`/digital-checking-account/transaction/${payload}`);
      commit("SET_RECEIPT_TRANSFER", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async depositByBill({ commit }, value) {
    commit("CLEAR_SEND_EMAIL");
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post(`/digital-checking-account/deposit/${value}`);
      commit("SET_BILL", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async sendEmail({ commit }, payload = {}) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { id, email } = payload;
      const params = email ? { email } : {};
      await api.post(`/digital-checking-account/transaction/send-mail/${id}`, params);
      commit("SUCCESS_SEND_EMAIL");
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async forgetPassword({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      await api.post("digital-checking-account/account/reset-password");
      commit("SET_SEND_EMAIL_PASSWORD", true);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async resetPassword({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    const { token, password } = payload;
    try {
      await api.put(`/digital-checking-account/account/password/${token}`, { password });
      commit("SET_RESET_PASSWORD", true);
    } catch (error) {
      commit("SET_RESET_PASSWORD", false);
    }
  },
  async validateTokenPasswordRecover({ commit }, token) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      await api.get(`/digital-checking-account/account/password/${token}/validate`);
      commit("SET_VALID_TOKEN", true);
    } catch (error) {
      commit("SET_VALID_TOKEN", false);
    }
  },
  async validateBarCode({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`digital-checking-account/payment/bill/${payload}`);
      commit("SET_BARCODE_DATA", data);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async newPayment({ commit, state }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/payment/bill", payload, {
        headers: {
          "digital-account-token": state.transferToken
        }
      });
      commit("SET_SUCCESS_PAYMENT", true);
      commit("SET_ID_TRANSFER", data.data.transactionId);
    } catch (error) {
      commit("SET_SUCCESS_PAYMENT", false);
      commit("SET_ERROR", handleError(error));
    }
  }
};
