import Vue from 'vue';
import api from '../../config/api';
import handleError from '../../utils/handleError';

export default {
  async login({ commit, dispatch }, payload) {
    commit('CLEAR_ERROR');
    commit('SET_LOGIN_CREATED', false);
    try {
      const { data } = await api.post('/user/auth/login', payload,
        { headers: { 'Content-type': 'application/json' } });
      localStorage.TOKEN = data.access_token;
      const tokenDecoded = Vue.$jwt.decode(data.access_token);
      api.defaults.headers.common.Authorization = `Bearer ${localStorage.TOKEN}`;

      commit('SET_LOGIN', tokenDecoded);
      commit('DigitalAccount/SET_SHOW_PASSWORD_MODAL', true, { root: true });
      commit('Pix/SET_SHOW_PIX_MODAL', true, { root: true });
      await dispatch('User/getProfile', false, { root: true });
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async forgetPassword({ commit }, payload = {}) {
    commit('SET_MESSAGE');
    try {
      const { data } = await api.post('/user/customer/reset-password', payload);
      commit('SET_MESSAGE', data.message);
    } catch (error) {
      commit('SET_MESSAGE', handleError(error));
      commit('SET_ERROR_PASSWORD', true);
    }
  },
  async resetPassword({ commit }, payload) {
    commit('SET_MESSAGE');
    const { token, password } = payload;

    try {
      await api.put(`/user/password/${token}`, { password });
      commit('SET_MESSAGE', true);
    } catch (error) {
      commit('SET_MESSAGE', false);
    }
  },
  async validateTokenPasswordRecover({ commit }, token) {
    commit('SET_VALID_TOKEN', false);
    try {
      await api.get(`/user/password/${token}/validate`);
      commit('SET_VALID_TOKEN', true);
    } catch (error) {
      commit('SET_VALID_TOKEN', false);
    }
  },
};
