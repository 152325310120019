export default {
  getRequests(state) {
    return state.requests;
  },
  getBalance(state) {
    return state.balance;
  },
  getTransferBalance(state) {
    return state.transferBalance;
  },
  getTotalPages(state) {
    return state.totalPages;
  },
  getSellerRequest(state) {
    return state.sellerRequest;
  },
  getError(state) {
    return state.error;
  },
  getAvailableValuesForRequest(state) {
    return state.availableValuesForRequest;
  },
  getNewTransfer(state) {
    return state.newTransfer;
  },
  getFilesUpload(state) {
    return state.filesUpload;
  },
  getAproveSchedule(state) {
    return state.aproveSchedule;
  },
  getReproveSchedule(state) {
    return state.reproveSchedule;
  },
  getUrl(state) {
    return state.url;
  },
  getExport(state) {
    return state.export;
  },
  getFileName(state) {
    return state.fileName;
  },
  getExportRequest(state) {
    return state.exportRequest;
  },
  getFileNameRequest(state) {
    return state.fileNameRequest;
  },
  getAnticipations(state) {
    return state.anticipations;    
  },
  getBalanceAnticipation(state) {
    return state.balanceAnticipation;
  },
  getSuccessAnticipation(state) {
    return state.successAnticipation;
  },
};
