export default {
  CUSTOMER_WALLET_STATEMENT_READ: {
    role: "customerWalletStatementRead",
    description: "Consultar Extrato"
  },
  CUSTOMER_WALLET_PAYMENT_WRITE: {
    role: "customerWalletPaymentWrite",
    description: "Realizar pagamento"
  },
  CUSTOMER_WALLET_PAYMENT_READ: {
    role: "customerWalletPaymentRead",
    description: "Consultar pagamentos"
  },
  CUSTOMER_WALLET_INTERNALTRANSFER_WRITE: {
    role: "customerWalletInternalTransferWrite",
    description: "Realizar Transf. entre contas Finago"
  },
  CUSTOMER_WALLET_EXTERNALTRANSFER_WRITE: {
    role: "customerWalletExternalTransferWrite",
    description: "Realizar Transf. para outros bancos"
  },
  CUSTOMER_WALLET_TRANSFER_READ: {
    role: "customerWalletTransferRead",
    description: "Consultar transferências"
  },
  CUSTOMER_WALLET_FAVORITE_CONTACT_WRITE: {
    role: "customerWalletFavoriteContactWrite",
    description: "Salvar contatos favoritos"
  },
  CUSTOMER_WALLET_DEPOSIT_BY_BILL_WRITE: {
    role: "customerWalletDepositByBillWrite",
    description: "Gerar Boleto para depósito"
  },
  CUSTOMER_WALLET_INSTANT_PAYMENT_ALIAS_WRITE: {
    role: "customerWalletInstantPaymentAliasWrite",
    description: "Gerenciar chaves PIX (criar, editar e excluir)"
  },
  CUSTOMER_WALLET_PAYMENT_BY_INSTANT_PAYMENT_WRITE: {
    role: "customerWalletPaymentByInstantPaymentWrite",
    description: "Realizar pagamento via PIX"
  },
  CUSTOMER_WALLET_CHARGE_BY_INSTANT_PAYMENT_WRITE: {
    role: "customerWalletChargeByInstantPaymentWrite",
    description: "Realizar cobrança via PIX"
  },
  CUSTOMER_WALLET_INSTANT_PAYMENT_TRANSFER_WRITE: {
    role: "customerWalletInstantPaymentTransferWrite",
    description: "Realizar transferência via PIX"
  },
  CUSTOMER_POSTEF_SUPPLIER_PAYMENT_REQUEST_WRITE: {
    role: "customerPosTefSupplierPaymentRequestWrite",
    description: "Solicitar Recebimento"
  },
  CUSTOMER_POSTEF_SUPPLIER_SCHEDULE_RECEIVABLES_READ: {
    role: "customerPosTefSupplierScheduleReceivablesRead",
    description: "Consultar Agenda Lojista"
  },
  CUSTOMER_POSTEF_SUPPLIER_SCHEDULE_RECEIVABLES_ANTECIPATION_WRITE: {
    role: "customerPosTefSupplierScheduleReceivablesAntecipationWrite",
    description: "Solicitar antecipação de recebíveis"
  },
  CUSTOMER_POSTEF_SELLER_PAYMENT_REQUEST_READ: {
    description: "Consultar solicitações de pagamentos",
    role: "customerPosTefSellerPaymentRequestRead"
  },
  CUSTOMER_POSTEF_SELLER_SALES_READ: {
    description: "Acessar Minhas Vendas",
    role: "customerPosTefSellerSalesRead"
  },
  CUSTOMER_POSTEF_SELLER_SUPPLIER_MANAGEMENT_WRITE: {
    description: "Gerenciar Fornecedores (adicionar, editar e excluir)",
    role: "customerPosTefSellerSupplierManagementWrite"
  },
  CUSTOMER_WALLET_RETURNS_INSTANT_PAYMENT_TRANSFER_WRITE: {
    description: "Realizar devolução transferência via PIX",
    role: "customerWalletReturnsInstantPaymentTransferWrite"
  }
};
