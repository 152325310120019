import Vue from 'vue';
import Router from 'vue-router';
import LoginValidate from '../config/loginValidate';
import authRoutes from './auth/auth';
import supplierRoutes from './supplier/supplier';
import sellerRoutes from './seller/seller';
import customerRouter from './customer/customer';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/404',
      component: () => import('@/views/commons/404.vue'),
      name: 'error 404',
      meta: {
        requiresAuth: true,
        showMenu: true,
        showToolbar: true,
        title: 'Página não encontrada',
        breadCrumb: '',
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
    ...authRoutes,
    // ...supplierRoutes,
    // ...sellerRoutes,
    ...customerRouter
  ],
});

router.beforeEach((to, from, next) => {
  const getToken = localStorage.TOKEN ? localStorage.TOKEN : null;
  const token = Vue.$jwt.decode(getToken);
  const loginValidate = new LoginValidate(to, from, token);
  document.title = to.meta.title ? `FINAGO | ${to.meta.title}` : 'FINAGO';
  next(loginValidate.validateToken());
});

export default router;
