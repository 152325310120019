export default {
  setValueSelectDataRegistration(state, payload) {
    state.dataRegistration[payload.key] = payload.value;
  },
  SET_BANKS(state, payload) {
    state.banks = payload;
  },
  SET_SEGMENTS(state, payload) {
    state.segments = payload;
  },
  SET_DATA_REGISTRATION(state, payload) {
    state.dataRegistration = payload;
  },
  SET_CONSULT_REGISTRATION(state, payload) {
    state.consultRegistration = payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_ERROR_PASSWORD(state, payload) {
    state.errorPassword = payload;
  },
  SET_SUCCESS_PASSWORD(state, payload) {
    state.successPassword = payload;
  },
  SET_SUCCESS_DOCUMENT(state, payload) {
    state.dataDocument = payload;
  },
  CLEAN_PASSWORD_MESSAGE(state) {
    state.successPassword = "";
    state.errorPassword = "";
  },
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload;
  },
  SET_STATUS(state, payload) {
    state.statusCustomer = payload;
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_DOMAIN_PROPERTYS(state, payload) {
    state.domainPropertys = payload;
  },
  SET_LINK_CONTRACT(state, payload) {
    state.linkContract = payload;
  },
  CLEAR_DATA_REGISTRATION(state) {
    state.dataRegistration = {
      representative: {
        address: {}
      }
    };
  },
  SET_USER_VALIDATE(state, payload) {
    state.userValidate = payload;
  },
  SET_NEW_USER(state, payload) {
    state.newUser = payload;
  }
};
