import api from '../../config/api';
import handleError from '../../utils/handleError';

export default {
  async setRelatedCustumers({ commit }, payload = {}) {
    try {
      const { data } = await api
        .post('/customer/related-customer/all', payload);
      commit('SET_TOTAL_PAGES', data.pages);
      commit('SET_RELATED_COSTUMERS', data.items);
    } catch ({ error }) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setAddProvider({ commit }, _payload = {}) {
    commit('CLEAR_ERROR');
    try {
      await api.post('/customer/related-customer', _payload);
      commit('SET_ADD_PROVIDER', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setIdsSuppliers({ commit }, _payload = {}) {
    commit('CLEAR_ERROR');
    try {
      await api.put('/customer/related-customer/ids', _payload);
      commit('SET_IDS_SUPPLIERS', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setDeleteSuppliers({ commit }, _payload = {}) {
    commit('CLEAR_ERROR');
    try {
      await api.delete('/customer/related-customer/ids', {
        data: _payload,
      });
      commit('SET_DELETE_SUPPLIERS', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setSupplierName({ commit }, document) {
    commit('CLEAR_ERROR');
    commit('SET_SUPPLIER_NAME');
    try {
      const { data } = await api.get(`/customer/document/${document}/name`);
      commit('SET_SUPPLIER_NAME', data.name);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
};
