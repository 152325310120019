import api from "../../config/api";
import handleError from "../../utils/handleError";

export default {
  async sendTokenPix({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("digital-checking-account/authorization/token");
      commit("SET_TOKEN_SENDED", true);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
      commit("SET_ERROR", handleError(error));
    }
  },
  async createPixKey({ commit }, payload) {
    commit("CLEAR_ERROR");

    const { token, ...rest } = payload;
    const headers = {
      "digital-account-user-token": payload.token
    };
    try {
      const { data } = await api.post("/digital-checking-account/alias", rest, {
        headers
      });
      commit("SET_CREATED_KEY", data);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
      commit("SET_ERROR", handleError(error));
    }
  },
  async createOtherKeys({ commit }, payload) {
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("/digital-checking-account/alias/validate", payload);
      commit("SET_CREATED_KEY", data);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
      commit("SET_ERROR", handleError(error));
    }
  },
  async getAllPixKeys({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/alias/filter", payload);
      commit("SET_ALL_DATA_KEYS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async deletePixKey({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");

    const { token, name } = payload;
    const headers = {
      "digital-account-user-token": payload.token
    };
    try {
      await api.delete(`/digital-checking-account/alias/${name}`, {
        headers
      });
      commit("SUCCESS_DELETE_KEY", true);
    } catch (error) {
      commit("Error/SET_ERROR", handleError(error), { root: true });
      commit("SET_ERROR", handleError(error));
    }
  },
  async checkStatusModal({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get("digital-checking-account/alias/modal");
      commit("SET_MODAL_STATUS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async updateStatusModal({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/alias/modal", payload);
      commit("SET_MODAL_STATUS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async generateQRcode({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/qrcode/generate", payload);
      commit("SET_QR_CODE_DATA", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async getPixBanks({ commit }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(
        "digital-checking-account/instant-payment/payment-service-providers"
      );
      commit("SET_BANKS", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async getAliasInfo({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.get(`/digital-checking-account/alias/check/${payload}`);
      commit("SET_ALIAS_INFO", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async validateTransfer({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/instant-payment/validate", payload);
      commit("SET_TRANSFER_DATA", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async generatePixToken({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post("digital-checking-account/authorization", payload);
      commit("SET_TRANSFER_TOKEN", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async setPayment({ commit }, payload) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");

    const { token, ...rest } = payload;
    const headers = {
      "digital-account-token": payload.token
    };
    try {
      const { data } = await api.post("digital-checking-account/instant-payment/pay", rest, {
        headers
      });
      commit("SET_PAYMENT_PIX", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
      commit("Error/SET_ERROR", handleError(error), { root: true });
    }
  },
  async instantPaymentTransfer({ commit }, { transactionId, token, ...payload }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    commit("DigitalAccount/SET_RECEIPT_TRANSFER", null, { root: true });

    const headers = {
      "digital-account-token": token
    };
    try {
      const {
        data
      } = await api.post(
        `/digital-checking-account/instant-payment/return/${transactionId}`,
        payload,
        { headers }
      );
      commit("SET_PAYMENT_TRANSFER_RETURN", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  },
  async instantPaymentTransferValidate({ commit }, { transactionId, ...payload }) {
    commit("Error/SET_ERROR", null, { root: true });
    commit("CLEAR_ERROR");
    try {
      const { data } = await api.post(
        `/digital-checking-account/instant-payment/validate/${transactionId}`,
        payload
      );
      commit("SET_PAYMENT_TRANSFER_RETURN", data);
    } catch (error) {
      commit("SET_ERROR", handleError(error));
    }
  }
};
