export default {
  SET_ERROR(state, _payload = true) {
    state.error = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_TOKEN_SENDED(state, _payload) {
    state.tokenSended = _payload;
  },
  SET_DATA_KEY(state, _payload) {
    state.dataKey = _payload;
  },
  SET_CREATED_KEY(state, _payload) {
    state.createdKey = _payload;
  },
  SET_KEY_EXISTS(state, _payload) {
    state.keyExists = _payload;
  },
  SET_ALL_DATA_KEYS(state, _payload) {
    state.allDataKeys = _payload;
  },
  SUCCESS_DELETE_KEY(state, _payload) {
    state.successDeleteKey = _payload;
  },
  SET_REQUEST(state, _payload) {
    state.request = _payload;
  },
  SET_MODAL_STATUS(state, payload) {
    state.modalStatus = payload;
  },
  SET_SHOW_PIX_MODAL(state, payload) {
    state.showPixModal = payload;
  },
  SET_QR_CODE_DATA(state, payload) {
    state.qrCodeData = payload;
  },
  SET_BANKS(state, payload) {
    state.banks = payload;
  },
  SET_ALIAS_INFO(state, payload) {
    state.aliasInformation = payload;
  },
  SET_TRANSFER_DATA(state, payload) {
    state.transferData = payload;
  },
  SET_TRANSFER_TOKEN(state, payload) {
    state.transferToken = payload;
  },
  SET_PAYMENT_PIX(state, payload) {
    state.paymentPix = payload;
  },
  SET_PAYMENT_TRANSFER_RETURN(state, payload) {
    state.paymentTransferReturn = payload;
  }
};
