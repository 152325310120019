import { saveAs } from 'file-saver';
import api from '../../config/api';
import handleError from '../../utils/handleError';

export default {
  async setSellerSolicitation({ commit }, payload) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .get(`/request/seller/${payload}/validate`);
      commit('SET_SELLER_REQUEST', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setRequests({ commit }, payload) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .post('/request/schedule-transfer/all', payload);
      commit('SET_TOTAL_PAGES', parseInt(data.pages, 10));
      commit('SET_REQUESTS', data.items);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setSSOUrl({ commit }) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .get('/request/seller/sso-create');
      commit('SET_URL', data.url);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setBalance({ commit }) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .get('/request/schedule-receivables/balance');
      commit('SET_BALANCE', data.balance);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setTransferBalance({ commit }) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .get('/request/schedule-transfer/balance');
      commit('SET_TRANSFER_BALANCE', data.balance);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async getValuesAvailableForRequest({ commit }, _payload = {}) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .post('/request/schedule-receivables/available', _payload);
      commit('SET_VALUES_AVAILABLE_FOR_REQUEST', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async setNewTransfer({ commit }, _payload = {}) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api
        .post('/request/schedule-transfer', _payload);
      commit('SET_NEW_TRANSFER', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async setFilesUpload({ commit }, _payload) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api.put(`/request/schedule-transfer/attach/${_payload.id}`, _payload.fd, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });
      commit('SET_FILES', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async aproveSchedule({ commit }, _payload) {
    commit('CLEAR_ERROR');

    try {
      await api.post(`/request/schedule-transfer/approve/${_payload}`, {});
      commit('SET_APROVE', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async reproveSchedule({ commit }, _payload) {
    commit('CLEAR_ERROR');
    try {
      await api.post(`/request/schedule-transfer/notapprove/${_payload}`, {});

      commit('SET_REPROVE', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  async downloadFileAttached({ commit }, _payload) {
    commit('CLEAR_ERROR');
    try {
      const request = new XMLHttpRequest();
      request.open('GET', _payload, true);
      request.setRequestHeader('Authorization', `Bearer ${localStorage.TOKEN}`);
      request.responseType = 'blob';
      const saveFile = () => {
        saveAs(request.response, _payload.split('/')[6]);
      };
      request.onload = saveFile;
      request.send();
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async setExportRequest({ commit }) {
    try {
      const { ...response } = await api.post('request/schedule-transfer/all/download');
      const fileName = response.headers['content-disposition'].split('"')[1];
      commit('SET_EXPORT_REQUEST', response.data);
      commit('SET_FILENAME_REQUEST', fileName);
    } catch ({ error }) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async setAllAnticipations({ commit }) {
    commit('CLEAR_ERROR');
    commit('SET_ANTICIPATIONS', null);
    try {
      const { data } = await api.get('request/anticipation/all');
      commit('SET_ANTICIPATIONS', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async setBalanceAnticipation({ commit }) {
    commit('CLEAR_ERROR');
    commit('SET_BALANCE_ANTICIPATION', null);
    try {
      const { data } = await api.get('request/anticipation/balance');
      commit('SET_BALANCE_ANTICIPATION', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async transferAnticipation({ commit }, payload = {}) {
    try {
      await api.post('request/anticipation/transfer', payload);
      commit('SET_SUCCESS_ANTICIPATION', true);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
};
