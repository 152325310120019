import api from '../../config/api';
import handleError from '../../utils/handleError';

export default {
  async setSchedule({ commit }, payload = {}) {
    try {
      const { data } = await api
        .post('/request/schedule-receivables/balance-of-all-sellers', payload);
      commit('SET_TOTAL_PAGES', data.pages);
      commit('SET_SCHEDULE', data.items);
    } catch ({ error }) {
      commit('SET_ERROR', handleError(error));
    }
  },

  async setExport({ commit } = {}) {
    try {
      const { ...response } = await api
        .post('request/schedule-receivables/balance-of-all-sellers/download', {});
      const fileName = response.headers['content-disposition'].split('"')[1];
      commit('SET_EXPORT', response.data);
      commit('SET_FILENAME', fileName);
    } catch ({ error }) {
      commit('SET_ERROR', handleError(error));
    }
  },
};
