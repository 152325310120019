import Vue from 'vue';
import api from '../../config/api';
import handleError from '../../utils/handleError';

export default {
  async setUserInfo({ commit, dispatch }, _payload) {
    commit('CLEAR_ERROR');
    try {
      const tokenDecoded = Vue.$jwt.decode(_payload);
      commit('Auth/SET_LOGIN', tokenDecoded, { root: true });
      api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.TOKEN}`;
      await dispatch('getProfile');
    } catch (error) {
      localStorage.removeItem('TOKEN');
      commit('SET_ERROR', error);
    }
  },
  async getProfile({ commit, dispatch }) {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api.get('/user/profile/');
      commit('SET_USER_INFO', data);
      dispatch('DigitalAccount/setAccount', false, { root: true });
    } catch (error) {
      localStorage.removeItem('TOKEN');
      commit('SET_ERROR', handleError(error));
    }
  },
  async getAllRoles({ commit }, document = '') {
    commit('CLEAR_ERROR');
    try {
      const { data } = await api.get(`/user/roles/available/${document}`);
      commit('SET_ALL_ROLES', data);
    } catch (error) {
      commit('SET_ERROR', handleError(error));
    }
  },
  hasRole({ state }, role) {
    return state.info.roles.includes(role);
  },
  toggleMenu({ commit, getters }) {
    commit("TOGGLE_MENU", !getters.getCollapsedMenu)    
  },
};
