<template>
  <v-app-bar class="top-bar">
    <span class="nameUser" v-if="getUserName">{{ this.getUserName }}, </span>

    <span class="welcome">{{ welcome }}</span>

    <v-spacer></v-spacer>

    <v-row class="tools-row">
    <v-btn icon class="icon-bell">
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <v-btn icon class="icon-email">
      <v-icon>mdi-email</v-icon>
    </v-btn>

    <v-divider class="divider" inset vertical></v-divider>

    <v-menu class="session-content">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <span class="nameUserConfig">{{nameCompany}}</span>
          <v-icon class="icon-account">mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(options, optionsKey) in userOptions"
          :key="optionsKey"
          @click="changeRoute(options.to)"
        >
          <v-icon class="icon-options">{{ options.userIcons }}</v-icon>
          <v-list-item-title class="userOptions">{{ options.userOption }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Toolbar',
  data: () => ({
    right: true,
    welcome: 'seja bem-vindo ao seu painel de controle.',
    nameCompany: 'Minha Conta',
    newUserName: '',
  }),
  computed: {
    ...mapGetters({
      getRole: 'Auth/getRole',
    }),
    getUserName() {
      const vm = this;
      return vm.$store.state.User.info && vm.$store.state.User.info.name
        ? vm.$store.state.User.info.name.toUpperCase().split(' ')[0]
        : '';
    },
    userOptions() {
      const options = [
        { userOption: 'Meu cadastro', userIcons: 'mdi-account-card-details', to: `/customer/myRegistration` },
        { userOption: 'Minhas senhas', userIcons: 'mdi-key-variant', to: `/customer/changePassword` },
        { userOption: 'Contratos', userIcons: 'mdi-file-document', to: `/customer/myContracts` },
        { userOption: 'Sair', userIcons: 'mdi-logout', to: '/login' },
      ];
      return options;
    },
  },

  methods: {
    ...mapMutations({
      setLogin: 'Auth/SET_LOGIN',
      setUserInfo: 'User/SET_USER_INFO',
    }),
    pushRouter() {
      this.$router.push({ name: `${this.$router.history.current.name}` });
    },
    changeRoute(route) {
      if (this.$route.path === route) return;
      if (route === '/login') {
        this.logout();
      }
      this.$router.push(route);
    },
    logout() {
      this.setLogin({
        login: '',
        roles: [],
      });
      this.setUserInfo({roles:[]});
      localStorage.clear();
    },
  },
};
</script>

<style lang="scss" scoped>


.breadCrumbs {
  margin-top: 5%;
  padding-left: 3%;
}

.v-menu__content {
  top: 50px !important;
  white-space: nowrap !important;
}
.v-toolbar__content {
  margin-left: -5% !important;
  white-space: nowrap !important;
}

.nameUserConfig {
  font-size: 12px;
  margin-left: 150%;
  margin-right: 10px;
  white-space: nowrap !important;
}
.welcome {
  color: black;
  padding-left: 0.5%;
  white-space: nowrap;
}
.tools-row {
  white-space: nowrap;
  justify-content: flex-end;
  margin-right: 15%;
}

.nameUser {
  color: black;
  font-weight: bold;
  white-space: nowrap;
}

.icon {
  color: rgb(117, 117, 117) !important;
}

.top-bar {
  color: #ffffff;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-left: 1%;
  position: fixed;
  white-space: nowrap !important;
  z-index: 1;
  top: 0;
  min-width: 64px;
  padding-left: 20%;
}

.v-btn {
  width: 0px !important;
  margin-right: 50px;
}

.icon-nav {
  color: #282e62 !important;
}

.userOptions {
  color: #8c8c8c;
  font-weight: bold;
  font-size: 15px;
}

.icon-options {
  padding-right: 10%;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}

.icon-email {
  z-index: 100;
}

</style>
