const customerRouter = [
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/Customer.vue'),
    redirect: { name: 'customer home' },
    meta: {
      requiresAuth: true,
      showMenu: true,
      showToolbar: true,
      breadCrumb: 'customer',
    },
    children: [
      {
        path: 'home',
        name: 'customer home',
        component: () => import('@/views/digitalAccount/home/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Home',
          breadCrumb: 'Home',
        },
      },
      {
        path: 'payments',
        name: 'My Payments',
        component: () => import('@/views/seller/myPayments/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pagamentos',
          breadCrumb: 'Pagamentos',
        },
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/commons/Contact.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Me Ajuda',
          breadCrumb: 'Me Ajuda',
        },
      },
      {
        path: 'myRegistration',
        name: 'myRegistration',
        component: () => import('@/views/commons/MyRegistration.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Meu Cadastro',
          breadCrumb: 'Meu Cadastro',
        },
      },
      {
        path: 'myContracts',
        name: 'myContracts',
        component: () => import('@/views/commons/MyContracts.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Contratos',
          breadCrumb: 'Contratos',
        },
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        component: () => import('@/views/commons/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Minhas senhas',
          breadCrumb: 'Minhas senhas',
        },
      },
      {
        path: 'mySuppliers',
        name: 'my suppliers',
        component: () => import('@/views/seller/mySuppliers/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Meus fornecedores',
          breadCrumb: 'Meus fornecedores',
        },
      },
      {
        path: 'digitalAccount/bankStatement',
        name: 'bank statement digital account',
        component: () => import('@/views/digitalAccount/bankStatement/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Extrato',
          breadCrumb: 'Extrato',
        },
      },
      {
        path: 'digitalAccount/deposits',
        name: 'deposits for digital account',
        component: () => import('@/views/digitalAccount/deposits/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Depositos',
          breadCrumb: 'Depósitos',
        },
      },
      {
        path: 'digitalAccount/payments',
        name: 'payments for digital account',
        component: () => import('@/views/digitalAccount/payments/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pagamentos',
          breadCrumb: 'Pagamentos',
        },
      },
      {
        path: 'digitalAccount/transfers',
        name: 'transfers for digital account',
        component: () => import('@/views/digitalAccount/transfers/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Transferências',
          breadCrumb: 'Transferências',
        },
      },
      {
        path: 'digitalAccount/newContact',
        name: 'contacts for digital account',
        component: () => import('@/views/digitalAccount/transfers/newContact.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Meus Contatos',
          breadCrumb: 'Meus Contatos',
        },
      },
      {
        path: 'digitalAccount/newTransference',
        name: 'data transference for digital account',
        component: () => import('@/views/digitalAccount/transfers/dataTransference.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Transferências',
          breadCrumb: 'Transferências',
        },
      },
      {
        path: 'digitalAccount/pix',
        name: 'pix for digital account',
        component: () => import('@/views/digitalAccount/pix/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pix',
          breadCrumb: 'Pix',
        },
      },
      {
        path: 'digitalAccount/pix/myKeys',
        name: 'pix keys for digital account',
        component: () => import('@/views/digitalAccount/pix/keys/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pix',
          breadCrumb: 'Pix',
        },
      },
      {
        path: 'digitalAccount/pix/charge',
        name: 'pix charge for digital account',
        component: () => import('@/views/digitalAccount/pix/charge/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pix',
          breadCrumb: 'Pix',
        }
      },
      {
        path: 'digitalAccount/pix/transfer',
        name: 'pix transfer for digital account',
        component: () => import('@/views/digitalAccount/pix/transfers/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Transfer Pix',
          breadCrumb: 'Pix',
        }
      },
      {
        path: 'digitalAccount/pix/pay',
        name: 'sler pix pay for digital account',
        component: () => import('@/views/digitalAccount/pix/payment/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Pay Pix',
          breadCrumb: 'Pix',
        }
      },
      {
        path: 'receipts',
        name: 'my recepits',
        component: () => import('@/views/supplier/myReceipts/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Meus recebimentos',
          breadCrumb: 'Meus recebimentos',
        },
      },
      {
        path: 'anticipation',
        name: 'Antecipação de Recebíveis',
        component: () => import('@/views/supplier/anticipation/Index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Antecipação de Recebíveis',
          breadCrumb: 'Antecipação de Recebíveis',
        },
      },
      {
        path: 'sellerSchedule',
        name: 'Agenda Lojista',
        component: () => import('@/views/supplier/sellerSchedule/index.vue'),
        meta: {
          requiresAuth: true,
          showMenu: true,
          showToolbar: true,
          title: 'Agenda Lojista',
          breadCrumb: 'Agenda Lojista',
        },
      },
    ],
  },
];


export default customerRouter;
