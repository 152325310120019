import roles from '../../config/permissions';

export default {
  home: { title: 'Home', icon: 'mdi-home', to: '/customer/home' },
  machine: {
    [roles.CUSTOMER_POSTEF_SUPPLIER_PAYMENT_REQUEST_WRITE.role]: { title: 'Recebimentos', to: '/customer/receipts' },
    [roles.CUSTOMER_POSTEF_SUPPLIER_SCHEDULE_RECEIVABLES_READ.role]: { title: 'Agenda Lojista', to: '/customer/sellerSchedule' },
    [roles.CUSTOMER_POSTEF_SUPPLIER_SCHEDULE_RECEIVABLES_ANTECIPATION_WRITE.role]: { title: 'Antecipação de Recebíveis', to: '/customer/anticipation' },
    [roles.CUSTOMER_POSTEF_SELLER_PAYMENT_REQUEST_READ.role]: { title: 'Pagamentos', to: '/customer/payments' },
    [roles.CUSTOMER_POSTEF_SELLER_SALES_READ.role]: { title: 'Minhas Vendas', to: '/customer/sales' },
    [roles.CUSTOMER_POSTEF_SELLER_SUPPLIER_MANAGEMENT_WRITE.role]:{ title: 'Meus Fornecedores', to: '/customer/mySuppliers' },
  },
  digital: {
    [roles.CUSTOMER_WALLET_STATEMENT_READ.role]: { title: 'Extrato', to: '/customer/digitalAccount/bankStatement' },
    [roles.CUSTOMER_WALLET_PAYMENT_READ.role]: { title: 'Pagamentos', to: '/customer/digitalAccount/payments' },
    [roles.CUSTOMER_WALLET_TRANSFER_READ.role]: { title: 'Transferências', to: '/customer/digitalAccount/transfers' },
    [roles.CUSTOMER_WALLET_DEPOSIT_BY_BILL_WRITE.role]: { title: 'Depósitos', to: '/customer/digitalAccount/deposits' },
    [roles.CUSTOMER_WALLET_INSTANT_PAYMENT_ALIAS_WRITE.role]:{ title: 'Pix', to: '/customer/digitalAccount/pix' },

  },
  contact: { title: 'Me Ajuda', icon: 'mdi-chat', to: '/customer/contact' },
};
