<template>
  <div>
    <DesktopToolbar v-if="$vuetify.breakpoint.mdAndUp"/>
    <MobileToolbar  v-else/>
    <v-progress-linear
      :active="getLoading"
      color="#d70472"
      :indeterminate="true"
      height="4"
      class="loading"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DesktopToolbar from './DesktopToolbar.vue';
import MobileToolbar from './MobileToolbar.vue';

export default {
  name: 'Toolbar',
  components: {
    DesktopToolbar, MobileToolbar,
  },
  computed:{
    ...mapGetters({
      getLoading: "Loading/getLoading"
    })
  }
};
</script>

<style lang="scss" scoped>
  .loading {
    position: fixed; 
    top: 64px; 
    right: 0; 
    left: 0; 
    z-index: 1;
  }
  @media only screen and (max-device-width: 767px) {
    .loading {
      top: 53px; 
    }
  }
</style>