export default {
  getRelatedCustumers(state) {
    return state.customers;
  },
  getTotalPages(state) {
    return state.totalPages;
  },
  getAddProvider(state) {
    return state.AddProvider;
  },
  getIdsSuppliers(state) {
    return state.IdsSuppliers;
  },
  getDeleteSuppliers(state) {
    return state.deleteSuppliers;
  },
  getError(state) {
    return state.error;
  },
  getSupplierName(state) {
    return state.supplierName;
  },
};
