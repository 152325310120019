<template>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="toggle-menu px-2">
        <v-divider />
        <div class="btn-toggle" @click="toggleMenu" :class="{'collapsed-menu' : getCollapsedMenu}">
            <v-col :cols="getCollapsedMenu ? '12' : '2'" class="px-0" 
            :align="getCollapsedMenu ? 'center' : 'start'">
                <v-icon color="#fff"> {{ icon }} </v-icon> 
            </v-col>
            <v-col v-if="!getCollapsedMenu" cols="10" align-self="center" class="px-0">
                Recolher menu
            </v-col>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            shrunkMenu: false
        }
    },
    computed: {
        ...mapGetters({
            getCollapsedMenu: 'User/getCollapsedMenu',
        }),
        icon() {
            const icon = this.getCollapsedMenu ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
            return icon
        }
    },
    methods: {
        ...mapActions({
            toggleMenu: 'User/toggleMenu',
        })
    }
}
</script>

<style>
    .toggle-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        color: #fff;
        font-size: .8125rem;
        font-weight: 500;
    }
    .toggle-menu .btn-toggle {    
        display: flex;
        border-top-right-radius: 7px; 
        border-bottom-right-radius: 7px; 
        cursor: pointer;
        z-index: 5;
    }
    .toggle-menu .collapsed-menu{
        justify-content: center;
    }
</style>