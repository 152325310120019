export default {
  getSchedule(state) {
    return state.schedule;
  },
  getTotalPages(state) {
    return state.totalPages;
  },
  getExport(state) {
    return state.export;
  },
  getFileName(state) {
    return state.fileName;
  },
  getError(state) {
    return state.error;
  },
};
