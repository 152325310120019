export default {
  SET_REQUESTS(state, _payload) {
    state.requests = _payload;
  },
  SET_BALANCE(state, _payload) {
    state.balance = _payload;
  },
  SET_TRANSFER_BALANCE(state, _payload) {
    state.transferBalance = _payload;
  },
  SET_TOTAL_PAGES(state, _payload) {
    state.totalPages = _payload;
  },
  SET_ERROR(state, _payload = true) {
    state.error = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_SELLER_REQUEST(state, _payload) {
    state.sellerRequest = _payload;
  },
  SET_VALUES_AVAILABLE_FOR_REQUEST(state, _payload) {
    state.availableValuesForRequest = _payload;
  },
  SET_NEW_TRANSFER(state, _payload) {
    state.newTransfer = _payload;
  },
  CLEAR_NEW_TRANSFER(state) {
    state.getSellerRequest = [];
    state.newTransfer = [];
    state.availableValuesForRequest = [];
  },
  SET_FILES(state, _payload) {
    state.filesUpload = _payload;
  },
  SET_APROVE(state, _payload) {
    state.aproveSchedule = _payload;
  },
  SET_REPROVE(state, _payload) {
    state.reproveSchedule = _payload;
  },
  SET_URL(state, _payload) {
    state.url = _payload;
  },
  SET_EXPORT(state, _payload) {
    state.export = _payload;
  },
  SET_FILE_NAME(state, _payload) {
    state.fileName = _payload;
  },
  SET_EXPORT_REQUEST(state, _payload) {
    state.exportRequest = _payload;
  },
  SET_FILENAME_REQUEST(state, _payload) {
    state.fileNameRequest = _payload;
  },
  SET_ANTICIPATIONS(state, _payload) {
    state.anticipations = _payload;
  },
  SET_BALANCE_ANTICIPATION(state, _payload) {
    state.balanceAnticipation = _payload;
  },
  SET_SUCCESS_ANTICIPATION(state, _payload) {
    state.successAnticipation = _payload;
  },
};
