export default {
  getRole(state) {
    return state.login.roles[0];
  },
  getLogin(state) {
    return state.login.login;
  },
  isSupplier(state) {
    return Boolean(state.login.roles.includes('supplier'));
  },
  isSeller(state) {
    return Boolean(state.login.roles.includes('seller'));
  },
  isInternal(state) {
    return Boolean(state.login.roles.includes('supplier-internal'));
  },
  hasRole(state) {
    return state.login.roles && state.login.roles.length > 0;
  },
  getMessagePassword(state) {
    return state.messagePassword;
  },
  getErrorPassword(state) {
    return state.errorPassword;
  },
  getError(state) {
    return state.error;
  },
  getValidToken(state) {
    return state.validToken;
  },
  getLogged(state) {
    return state.logged;
  },
};
