import axios from 'axios';
import store from '../store';

const api = axios.create({
  baseURL: process.env.VUE_APP_BACK || '/api',
  headers: {
    'Cache-Control': 'no-cache',
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  store.commit("Loading/SET_LOADING", true);
  return config;
}, (error) => {
  store.commit("Loading/SET_LOADING", false);
  return Promise.reject(error);
});
api.interceptors.response.use((config) => {
  store.commit("Loading/SET_LOADING", false);
  return config;
}, (error) => {
  store.commit("Loading/SET_LOADING", false);
  return Promise.reject(error);
});

export default api;
