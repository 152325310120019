import store from '../store';

export default class LoginValidate {
  constructor(to, from, token) {
    this.from = from;
    this.to = to;
    this.token = token;
    this.needToAuthorize = to.meta.requiresAuth;
    this.isAnonymous = this.token === null;
  }

  // eslint-disable-next-line class-methods-use-this
  getPageToGo(page) {
    return { name: page };
  }

  validateToken() {
    if (this.getAnonymousRoutes()) {
      return true;
    }

    if (this.needToAuthorize && this.token) {
      const hasRole = store.getters['Auth/hasRole'];
      const isSupplier = store.getters['Auth/isSupplier'];
      const isSeller = store.getters['Auth/isSeller'];

      if (this.to.path.includes('/seller/sales')) {
        return this.getPageToGo(this.from.path);
      }

      if (hasRole && isSupplier && !this.to.path.includes('supplier')) {
        return this.getPageToGo('supplier home');
      }

      if (hasRole && isSeller && !this.to.path.includes('seller')) {
        return this.getPageToGo('seller home');
      }
      return true;
    }

    if (this.needToAuthorize && !this.token) {
      return this.getPageToGo('login');
    }

    return this.eraseToken();
  }

  getAnonymousRoutes() {
    const openRoutes = ['login', 'registration', 'error'];
    const search = openRoutes.filter(isOpen => this.to.name === isOpen);
    const routeEnabled = search.length > 0;
    return routeEnabled;
  }

  eraseToken() {
    localStorage.removeItem('TOKEN');
    this.getPageToGo(this.to.name);
  }
}
