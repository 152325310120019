export default {
  requests: [],
  balance: 0,
  transferBalance: 0,
  totalPages: '',
  error: null,
  sellerRequest: [],
  availableValuesForRequest: [],
  newTransfer: [],
  filesUpload: [],
  aproveSchedule: false,
  reproveSchedule: false,
  url: '',
  export: null,
  fileName: '',
  fileNameRequest: '',
  exportRequest: '',
  anticipations: null,
  balanceAnticipation: '',
  successAnticipation: false,
};
