<template>
  <v-list class="content-menu" dense nav v-if="info.roles.length > 0">
    <v-list-item>
      <v-list-item-content class="title-item">
        <v-list-item-title
          v-if="$vuetify.breakpoint.mdAndUp" class="logo-desktop" @click="changeRoute(home.to)">
          <v-row v-if="getCollapsedMenu" justify="center" class="mb-3">
            <v-img src="../../assets/logo-collapsed.svg" 
            max-width="30"
            class="logo mt-6" alt="Finago"/>
          </v-row>
          <img v-else src="../../assets/logo-menu.png" class="logo" alt="Finago"/>
        </v-list-item-title>
        <v-list-item-title v-else class="title-logo">
          <img src="../../assets/logo-menu.png" class="logo-mobile" alt="Finago" />
          <span @click="hideMenu" class="icon">
            <v-icon class="close-icon ">mdi-window-close</v-icon>
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="list-items pb-3" id="list">
      <div :key="home.title" link class="list-menu" id="list-menu">
        <div tag="li" :class="isActive(home.to)">
          <v-row v-if="getCollapsedMenu" justify="center" class="section-menu ma-0" @click="changeRoute(home.to)">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on"><v-icon class="py-2">{{ home.icon }}</v-icon></span>
              </template>
              <span>{{home.title}}</span>
            </v-tooltip>
          </v-row>
          <v-list-item v-else class="dividers">
            <v-list-item-icon>
              <v-icon class="icon-item">{{ home.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="changeRoute(home.to)">
              <v-list-item-title class="item">{{home.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </div>
    <div v-if="digitalItem.length > 0">
      <v-divider md="10"></v-divider>
      <div class="list-items pb-3">
        <v-col class="menu-select section-menu pb-0">
          <v-row v-if="getCollapsedMenu" justify="center" class="ma-0" @click="showDigitalMenu">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on"><v-icon>mdi-cash</v-icon></span>
              </template>
              <span>Conta Digital</span>
            </v-tooltip>
          </v-row>
          <v-row v-else class="section-menu my-0" @click="digitalMenu">
            <v-col md="2" cols="2" class="pb-0"><v-icon>mdi-cash</v-icon></v-col>
            <v-col md="7" cols="7" class="pb-0"><span>Conta Digital</span></v-col>
            <v-col md="3" cols="1" v-if="!showDigital" class="arrowDigital pb-0">
              <v-icon>mdi-menu-down</v-icon>
            </v-col>
            <v-col v-if="showDigital" md="3" class="arrowDigital pb-0">
              <v-icon>mdi-menu-up</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <div v-if="showDigital">
          <div v-for="digitalItems in digitalItem"
            :key="digitalItems.title" link class="list-menu" id="list-menu">
            <div tag="li" :class="isActive(digitalItems.to)">
              <v-list-item v-if="!getCollapsedMenu" class="dividers">
                <v-list-item-icon>
                  <v-icon class="icon-item"></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="changeRoute(digitalItems.to)">
                  <v-list-item-title class="item">{{digitalItems.title}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-divider md="10"></v-divider>
    <div class="list-items pb-3" v-if="machineItem.length > 0">
      <v-col class="menu-select pb-0" justify="center">
        <v-row v-if="getCollapsedMenu" justify="center" class="section-menu ma-0" @click="showMachineMenu()">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <span v-on="on"><v-icon>mdi-deskphone</v-icon></span>
            </template>
            <span>Maquininha</span>
          </v-tooltip>
        </v-row>
        <v-row v-else class="section-menu my-0" @click="machineMenu">
          <v-col md="2" cols="2" class="pb-0"><v-icon>mdi-deskphone</v-icon></v-col>
          <v-col md="7" cols="7" class="pb-0"><span>Maquininha</span></v-col>
          <v-col v-if="!showMachine" md="3" cols="1" class="arrowMachine pb-0">
            <v-icon>mdi-menu-down</v-icon>
          </v-col>
          <v-col v-if="showMachine" md="3" cols="1" class="arrowMachine pb-0">
            <v-icon>mdi-menu-up</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="showMachine">
        <div v-for="machineItems in machineItem"
          :key="machineItems.title" link class="list-menu" id="list-menu">
          <div tag="li" :class="isActive(machineItems.to)">
            <v-list-item v-if="!getCollapsedMenu" class="dividers">
              <v-list-item-icon>
                <v-icon class="icon-item"></v-icon>
              </v-list-item-icon>
              <v-list-item-content @click="changeRoute(machineItems.to)">
                <v-list-item-title class="item">{{machineItems.title}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </div>
    <v-divider md="10"></v-divider>
    <div class="list-items py-3" id="list">
      <div :key="contact.title" link class="list-menu" id="list-menu">
        <div tag="li" :class="isActive(contact.to)">
          <v-row v-if="getCollapsedMenu" @click="changeRoute(contact.to)"  class="section-menu ma-0" justify="center">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on"><v-icon class="icon-item py-2">{{ contact.icon }}</v-icon></span>
              </template>
              <span>{{contact.title}}</span>
            </v-tooltip>
          </v-row>
          <v-list-item v-else class="dividers">
            <v-list-item-icon>
              <v-icon class="icon-item">{{ contact.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="changeRoute(contact.to)">
              <v-list-item-title class="item">{{contact.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </div>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Items from './consts';

export default {
  name: 'Menu',
  data() {
    return {
      menuMachine: Items.machine,
      menuDigital: Items.digital,
      home: Items.home,
      contact: Items.contact,
      machineItem: [],
      digitalItem: [],
      contactItem: [],
      notAdmin: true,
      showMachine: true,
      showDigital: true,
      selected: 0,
    };
  },
  computed: {
    ...mapGetters({
      adiqUrl: 'Request/getUrl',
      role: 'Auth/getRole',
      isInternal: 'Auth/isInternal',
      digitalAccount: 'DigitalAccount/getAccount',
      showAccount: 'DigitalAccount/getShowAccount',
      info: 'User/getInfo',
      getCollapsed: 'User/getCollapsedMenu',
    }),
    getCollapsedMenu(){
      return this.getCollapsed && this.$vuetify.breakpoint.mdAndUp
    },
    getMachine(){
     const values = {...this.menuMachine};
      Object.keys(this.menuMachine).map(el => {
        if(!this.info.roles.includes(el) ) delete values[el]
      })
      const data = [...Object.values(values)]
      return data;
    },
    getDigital(){
     const values = {...this.menuDigital};
      Object.keys(this.menuDigital).map(el => {
        if(!this.info.roles.includes(el) ) delete values[el]
      })
      const data = [...Object.values(values)]
      return data;
    },
  },
  methods: {
    ...mapActions({
      setSSOUrl: 'Request/setSSOUrl',
      toggleMenu: 'User/toggleMenu'
    }),
    isActive(route) {
      return this.$route.path === route ? 'active' : '';
    },
    selectItems() {
      this.machineItem = this.getMachine;
      this.digitalItem = this.getDigital;
    },
    digitalMenu() {
      this.showDigital = !this.showDigital;
    },
    machineMenu() {
      this.showMachine = !this.showMachine;
    },
    async changeRoute(route) {
      if (this.$route.path === route) return;
      if (route === '/customer/sales') {
        await this.setSSOUrl();
        window.open(this.adiqUrl);
        return;
      } 
      this.$router.push(route);
    },
    hideMenu() {
      this.$bus.$emit('toggleMenu', false);
    },
    showDigitalMenu(){
      this.toggleMenu()
      this.showDigital = true
    },
    showMachineMenu(){
      this.toggleMenu()
      this.showMachine = true
    }
  },
  created() {
    this.selectItems();
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__icon:first-child {
    margin-right: 5px;
}
.logo-desktop {
  cursor: pointer;
}
.title-item {
  margin-bottom: 20%;
}
.active {
  background: rgba(255, 255, 255, 0.315);
  list-style: none;
  border-radius: 5px;
  width: 100%;
}
.logo-mobile {
  margin-top: 10%;
  width: 80%;
  height: auto;
}
.icon {
  margin-left: 15px;
  margin-bottom: 10px;
}
.icon-item {
  cursor: pointer;
}

.close-icon {
  margin-top: -20%;
}
.list-menu {
  list-style: none;
  width: 98%;
}
.item {
  font-weight: 600;
  margin-left: 15px;
  cursor: pointer;
}
.menu {
  margin-top: 100px;
}
.section-menu {
  align-items: center;
  cursor: pointer;
}
.menu-select {
  font-size: .8125rem;
}
@media only screen and (max-device-width: 767px) {
  .title-item {
  margin-bottom: 10%;
}

}
</style>
