export default {
  getError(state) {
    return state.error;
  },
  getTokenSended(state) {
    return state.tokenSended;
  },
  getDataKey(state) {
    return state.dataKey;
  },
  getCreatedKey(state) {
    return state.createdKey;
  },
  getKeyExists(state) {
    return state.keyExists;
  },
  getAllDataKeys(state) {
    return state.allDataKeys;
  },
  getSuccessDeleteKey(state) {
    return state.successDeleteKey;
  },
  getRequest(state) {
    return state.request;
  },
  getModalStatus(state) {
    return state.modalStatus;
  },
  getShowPixModal(state) {
    return state.showPixModal;
  },
  getQrCodeData(state) {
    return state.qrCodeData;
  },
  getBanks(state) {
    return state.banks;
  },
  getAliasInformation(state) {
    return state.aliasInformation;
  },
  getTransferData(state) {
    return state.transferData;
  },
  getTransferToken(state) {
    return state.transferToken;
  },
  getPaymentPix(state) {
    return state.paymentPix;
  },
  getTransferReturn(state) {
    return state.paymentTransferReturn;
  }
};
