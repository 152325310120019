<template>
  <v-navigation-drawer
    :class="getCollapsedMenu ? 'collapsed-menu' :  ''"
    v-if="$vuetify.breakpoint.mdAndUp"
    permanent  :color="color" :left="left" dark app>
    <div class="content-side">
      <Menu class="content-menu"/>
      <ToggleSide class="content-toggle"/>
    </div>
  </v-navigation-drawer>
  <v-navigation-drawer
    class="menu-side"
    v-model="drawer"
    v-else absolute
    temporary height="100%"
    :color="color" :left="left" dark app>
    <Menu>
    </Menu>
  </v-navigation-drawer>
</template>
<script>

import Menu from './Menu.vue';
import { mapGetters } from 'vuex';
import ToggleSide from './ToggleSide'
export default {
  name: 'Sidebar',
  components: { Menu, ToggleSide },
  data() {
    return {
      drawer: false,
      color: '#282E62',
      left: true,
      background: false,
    };
  },
  computed: {
    ...mapGetters({
        getCollapsedMenu: 'User/getCollapsedMenu',
    })
  },
  created() {
    this.$bus.$on('toggleMenu', (payload) => {
      this.drawer = payload;
    });

    this.$loadScript(process.env.VUE_APP_CHAT);
  },
  destroyed() {
    this.$unloadScript(process.env.VUE_APP_CHAT);
    const element = document.getElementById('designstudio-button');
    element.remove();
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 156px;
}
.title-logo {
  display: flex;
  justify-content: center;
}
.item {
  font-weight: normal;
  margin-left: 15px;
}
.menu {
  margin-top: 100px;
}
.underline {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.menu-side {
  position: fixed;
}
.collapsed-menu{
  width: 70px !important;
}
.content-side{
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr 70px;
  grid-template-columns: 100%;
  grid-template-areas: "contentMenu" "contentToggle";
}
.content-menu {
  grid-area: contentMenu;
}
.content-toggle {
  grid-area: contentToggle;
}
</style>
