export default {
  SET_SCHEDULE(state, _payload) {
    state.schedule = _payload;
  },
  SET_TOTAL_PAGES(state, _payload) {
    state.totalPages = _payload;
  },
  SET_ERROR(state, _payload) {
    state.error = _payload;
  },
  SET_EXPORT(state, _payload) {
    state.export = _payload;
  },
  SET_FILENAME(state, _payload) {
    state.fileName = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
};
