export default {
  error: null,
  tokenSended: false,
  dataKey: null,
  createdKey: null,
  keyExists: null,
  allDataKeys: null,
  successDeleteKey: false,
  request: null,
  modalStatus: null,
  showPixModal: false,
  qrCodeData: [],
  banks: [],
  aliasInformation: [],
  transferData: [],
  transferToken: "",
  paymentPix: [],
  paymentTransferReturn: []
};
