import Vue from 'vue';
import VueJWT from 'vuejs-jwt';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueFormJsonSchema from 'vue-form-json-schema';
import vuetify from '@/plugins/vuetify';
import Vuetify from 'vuetify';
import './assets/styles/_main.scss';
import 'vuetify/dist/vuetify.min.css';
import 'vue-swatches/dist/vue-swatches.min.css';
import Draggable from 'vuedraggable';
import Swatches from 'vue-swatches';
import LoadScript from 'vue-plugin-load-script';

import store from './store/index';
import router from './router/router';
import App from './App.vue';

require('./config/eventBus');

Vue.component('vue-form-json-schema', VueFormJsonSchema);
Vue.component('swatches', Swatches);
Vue.component('draggable', Draggable);

Vue.use(VueJWT);
Vue.use(LoadScript);
Vue.use(Vuetify);
Vue.use(VueMoment, { moment });

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'http://finago.facsolucoes.com.br/assets/modalReceipt.css'
  ]
}

Vue.use(options);

Vue.config.productionTip = false;

moment.tz.setDefault('Brasil/São_Paulo');
moment.locale('pt-br');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
