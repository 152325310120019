import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './auth/index';
import User from './User/index';
import DigitalAccount from './digitalAccount/index';
import Registration from './registration/index';
import Request from './request/index';
import Schedule from './schedule/index';
import relatedCustumer from './relatedCustumer/index';
import Error from './error/index';
import Pix from './pix/index'
import Loading from './loading/index'
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Auth,
    User,
    Registration,
    Request,
    Schedule,
    relatedCustumer,
    DigitalAccount,
    Pix,
    Error,
    Loading
  },
});

export default store;
