export default {
  SET_USER_INFO(state, _payload) {
    state.info = _payload;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
  SET_ERROR(state, _payload) {
    state.error = _payload;
  },
  SET_ALL_ROLES(state, _payload) {
    state.rolesAvailable = _payload
  },
  TOGGLE_MENU(state, payload) {
    state.collapsedMenu = payload
  }
};
